import React from 'react';
import { Flex, Box, Text, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import MoneyChart from 'components/Charts/MoneyChart';
import { CardSkele } from 'components/Skeletons/Card';
import { Card } from './Card';

const legend = [
  { label: 'Faturado', color: 'green.900' },
  { label: 'Aguardando aprovação', color: 'yellow.500' },
  { label: 'Não iniciado', color: 'blue.100' },
];

export const MoneyCard: React.FC<MoneyCardProps> = ({
  processed,
  notStarted,
  waiting,
  isLoading,
}) => {
  if (isLoading)
    return (
      <CardSkele
        flexDir="column"
        alignItems="center"
        py="12px"
        minW="192px"
        w="192px"
        h="228px"
      >
        <Skeleton h="16px" w="48px" />
        <SkeletonCircle
          mb="24px"
          mt="10px"
          borderRadius="full"
          w="72px"
          h="72px"
        />
        {legend.map(({ label, color }) => (
          <Flex
            ml="18px"
            w="100%"
            mt="16px"
            gap="6px"
            alignItems="center"
            key={label}
          >
            <Skeleton bg={color} w="6px" h="6px" borderRadius="full" />
            <Skeleton w="120px" h="12px" fontSize="12px" />
          </Flex>
        ))}
      </CardSkele>
    );

  return (
    <Card minW="192px" w="192px" h="228px">
      <Text textAlign="center" textColor="text.600">
        Status
      </Text>
      <MoneyChart
        processed={processed}
        notStarted={notStarted}
        waiting={waiting}
      />

      {legend.map(({ label, color }) => (
        <Flex mt="6px" gap="6px" alignItems="center" key={label}>
          <Box bg={color} w="6px" h="6px" borderRadius="full" />
          <Text fontSize="12px" textColor="text.100">
            {label}
          </Text>
        </Flex>
      ))}
    </Card>
  );
};
