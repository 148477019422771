import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import {
  useClearNotificationsMutation,
  useNotificationListQuery,
  useViewNotificationMutation,
} from '@spartalabs/pdc-core';
import { useCustomToast } from 'hooks';
import { DrawerCentral } from './components/DrawerCentral';
import { PopoverCentral } from './components/PopoverCentral';

export const NotificationCentral = () => {
  const [isMd] = useMediaQuery('(min-width: 768px)');
  const navigate = useNavigate();
  const [viewed, setViewed] = useState<string[]>([]);

  const { handleToast } = useCustomToast();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, isError, isFetching, isLoading, refetch } =
    useNotificationListQuery();

  const handleTotal = useMemo(() => {
    const totalNots = data?.length || 0;
    const viewedNots = viewed.length || 0;

    if (totalNots <= viewedNots) return 0;
    else return totalNots - viewedNots;
  }, [viewed, data]);

  const [viewNot] = useViewNotificationMutation();
  const [clearNot, { isLoading: loadingAll }] = useClearNotificationsMutation();

  const handleView = useCallback(
    async ({
      id,
      type,
      isAll,
      plate,
    }: {
      id?: string;
      type?: number;
      isAll?: boolean;
      plate?: string;
    }) => {
      try {
        if (isAll) {
          await clearNot().unwrap();
          await refetch();
        } else if (id && type) {
          if (!viewed.includes(id)) {
            await viewNot({ id, type }).unwrap();
            setViewed(view => [...view, id]);
          }
          if (plate) navigate(`/gestao/veiculos/${plate}`);
        }
      } catch {
        if (isAll)
          return handleToast({
            step: 4,
            action: 'limpar as notificações',
          });
      }
    },
    [viewed],
  );

  useEffect(() => {
    refetch();
  }, []);

  return isMd ? (
    <PopoverCentral
      total={handleTotal}
      notification={data || []}
      viewNot={handleView}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      clearAll={() => handleView({ isAll: true })}
      loadingClear={loadingAll}
      isLoading={isFetching || isLoading}
      isError={isError}
      refetch={refetch}
    />
  ) : (
    <DrawerCentral
      total={handleTotal}
      notification={data || []}
      viewNot={handleView}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      clearAll={() => handleView({ isAll: true })}
      loadingClear={loadingAll}
      isLoading={isFetching}
      isError={isError}
      refetch={refetch}
    />
  );
};
