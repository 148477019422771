import React, { useState } from 'react';
import { IoMdNotificationsOutline } from 'react-icons/io';
import {
  Text,
  Center,
  Box,
  Icon,
  Divider,
  Flex,
  Button,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  Image,
  Spinner,
} from '@chakra-ui/react';
import { EmptyNot } from 'assets';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { Loading } from 'components/Loading';
import { Notification } from 'components/Notification';

export const PopoverCentral: React.FC<CentralProps> = ({
  notification,
  viewNot,
  isOpen,
  onClose,
  onOpen,
  clearAll,
  isLoading,
  isError,
  refetch,
  loadingClear,
  total,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  if (!notification) return <></>;

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement="bottom"
    >
      <PopoverTrigger aria-label="abrir notificações" data-cy="notification">
        <Flex cursor="pointer">
          {total > 0 && (
            <Center
              mt={isLoading ? '2px' : '-6px'}
              ml="12px"
              position="absolute"
              w="18px"
              bg="red"
              borderRadius="full"
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="grey.200"
                  color="ocean.200"
                  size="xs"
                  position="absolute"
                  zIndex={1}
                  alignSelf="center"
                />
              ) : (
                <Text fontWeight={500} fontSize="12px">
                  {total}
                </Text>
              )}
            </Center>
          )}

          <Icon as={IoMdNotificationsOutline} color="white" w="24px" h="24px" />
        </Flex>
      </PopoverTrigger>

      <PopoverContent w="100%" mt="20px" p="0px" maxHeight="90vh" color="white">
        <PopoverArrow />
        <PopoverBody overflowY="auto" overflowX="hidden" p="0px">
          {notification.length === 0 ? (
            isLoading ? (
              <Center h="280px" flexDir="column" minW="600px">
                <Loading />
              </Center>
            ) : isError ? (
              <Center h="280px" flexDir="column" minW="600px">
                <ErrorCard
                  refetch={() => refetch()}
                  border="none"
                  shadow="none"
                />
              </Center>
            ) : (
              <Center mt="80px" flexDir="column" minW="600px">
                <Image alt="remover logo" src={EmptyNot} />
                <Text
                  mt="10px"
                  textAlign="center"
                  mb="80px"
                  textColor="text.500"
                >
                  Você não possui notificações não lidas
                </Text>
                <Divider />
              </Center>
            )
          ) : (
            <>
              {' '}
              <Text
                lineHeight="20px"
                p="0px"
                py="24px"
                ml="28px"
                fontSize="32px"
                fontWeight={500}
                textAlign="start"
                color="text.100"
              >
                Notificações
              </Text>
              <Divider borderWidth="1px" borderColor="grey.600" opacity="0.2" />
              {notification
                .slice(0, showMore ? notification.length : 5)
                .map(item => (
                  <Box
                    display="block"
                    w={{ base: '340px', md: '643px' }}
                    key={item.id}
                    p="0"
                  >
                    <Notification
                      icon={item.type}
                      onNavigate={() =>
                        viewNot({
                          id: item.id,
                          type: item.type,
                          plate: item.plate,
                        })
                      }
                      type="central"
                      title={item.title}
                      content={item.content}
                    />

                    <Divider
                      borderWidth="1px"
                      borderColor="grey.600"
                      opacity="0.2"
                    />
                  </Box>
                ))}
            </>
          )}
          {isError || isLoading ? (
            <></>
          ) : (
            <Flex alignItems="center">
              <Button
                isLoading={loadingClear}
                onClick={() => clearAll()}
                isDisabled={notification.length === 0}
                h="64px"
                borderRadius={0}
                border="none"
                variant="outline"
                w="50%"
              >
                Limpar todas
              </Button>
              <Divider
                aria-hidden="true"
                borderWidth="1px"
                borderColor="grey.600"
                opacity="0.2"
                orientation="vertical"
                h="36px"
              />
              <Button
                isDisabled={notification.length <= 5}
                onClick={() => setShowMore(show => !show)}
                h="64px"
                borderRadius={0}
                border="none"
                variant="outline"
                w="50%"
              >
                {showMore
                  ? 'Mostrar menos'
                  : ` Mostrar mais ${
                      notification.length > 5 ? notification.length - 5 : ''
                    } notificações`}
              </Button>
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
