import React, { useRef, useEffect, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { MdAttachMoney } from 'react-icons/md';
import { Center, Icon } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  ChartOptions,
  ChartData,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors } from 'theme/components/colors';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const MoneyChart: React.FC<MoneyCardProps> = ({
  processed = 0,
  notStarted = 0,
  waiting = 0,
}) => {
  const defaultOptions: ChartOptions<'doughnut'> = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: false,
      events: [],
      layout: {
        padding: 12,
      },
      plugins: {
        datalabels: {
          formatter: value => {
            return value;
          },
          color: '#303030',
          anchor: 'end',
          align: 'end',
          offset: -2,
          font: {
            size: 10,
          },
        },
      },
    }),
    [],
  );

  const chartRef = useRef<ChartJS<'doughnut'>>(null);

  const data: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [waiting, notStarted, processed],
        backgroundColor: [
          colors.yellow[500],
          colors.blue[0],
          colors.green[900],
        ],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        cutout: '86%',
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
  }, []);

  return (
    <Center mb="8px" mt="-12px">
      <Center
        boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
        border="1px solid"
        borderColor="grey.200"
        mt="10px"
        borderRadius="full"
        w="58px"
        h="58px"
        position="absolute"
        bg="white"
      >
        <Icon
          borderRadius="8px"
          w="38px"
          h="42px"
          as={MdAttachMoney}
          color="green.100"
        />
      </Center>
      <Doughnut
        height="112px"
        aria-label="Gráfico de rosquinha"
        ref={chartRef}
        options={defaultOptions}
        data={data}
      />
    </Center>
  );
};

export default MoneyChart;
