import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { MdOutlineNotifications } from 'react-icons/md';
import {
  Text,
  Center,
  Box,
  Icon,
  Flex,
  Button,
  Image,
  DrawerContent,
  DrawerHeader,
  Drawer,
  DrawerBody,
  VStack,
  DrawerFooter,
  Spinner,
} from '@chakra-ui/react';
import { EmptyNot } from 'assets';
import { Card } from 'components/Cards/Card';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { Loading } from 'components/Loading';

export const DrawerCentral: React.FC<CentralProps> = ({
  notification,
  viewNot,
  isOpen,
  onClose,
  onOpen,
  clearAll,
  isLoading,
  isError,
  refetch,
  loadingClear,
  total,
}) => {
  return (
    <>
      <Flex cursor="pointer" onClick={onOpen}>
        {total > 0 && (
          <Center
            mt={isLoading ? '2px' : '-6px'}
            ml="12px"
            position="absolute"
            w="18px"
            bg="red"
            borderRadius="full"
          >
            {isLoading ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="grey.200"
                color="ocean.200"
                size="xs"
                position="absolute"
                zIndex={1}
                alignSelf="center"
              />
            ) : (
              <Text fontWeight={500} fontSize="12px">
                {total}
              </Text>
            )}
          </Center>
        )}

        <Icon as={IoMdNotificationsOutline} color="white" w="24px" h="24px" />
      </Flex>

      <Drawer
        isOpen={isOpen}
        closeOnOverlayClick
        closeOnEsc
        size="xs"
        onClose={onClose}
      >
        <DrawerHeader />
        <DrawerContent w="100%" p="0px" color="white">
          <DrawerBody overflowY="auto" overflowX="hidden" p="0px">
            <Text
              lineHeight="20px"
              p="0px"
              pt="20px"
              ml="18px"
              fontSize="20px"
              fontWeight={700}
              textAlign="start"
              color="mutable.100"
            >
              Notificações
            </Text>

            {notification.length === 0 ? (
              isLoading ? (
                <Center h="80%">
                  <Loading />
                </Center>
              ) : isError ? (
                <Center h="80%">
                  <ErrorCard
                    refetch={() => refetch()}
                    border="none"
                    shadow="none"
                  />
                </Center>
              ) : (
                <Center h="90%" flexDir="column">
                  <Image alt="remover logo" src={EmptyNot} />
                  <Text
                    w="180px"
                    mt="20px"
                    textAlign="center"
                    mb="80px"
                    textColor="text.500"
                  >
                    Você não possui notificações não lidas
                  </Text>
                </Center>
              )
            ) : (
              <>
                <VStack mt="18px" px="16px" gap="14px">
                  {notification.map(item => {
                    const isCar = item.type === 1;
                    return (
                      <Card
                        cursor="pointer"
                        onClick={() =>
                          viewNot({
                            id: item.id,
                            type: item.type,
                            plate: item.plate,
                          })
                        }
                        key={item.id}
                        minW="100%"
                        maxW="70px"
                        minH="78px"
                      >
                        <Flex>
                          <Center
                            minW="40px"
                            h="40px"
                            mr="8px"
                            borderRadius="8px"
                            bg={isCar ? 'green.200' : 'grey.200'}
                          >
                            <Icon
                              as={
                                isCar ? AiOutlineCheck : MdOutlineNotifications
                              }
                              bg={isCar ? '#4bae4f' : 'transparent'}
                              p={isCar ? '2px' : '0px'}
                              w={isCar ? '20PX' : '24px'}
                              h={isCar ? '20PX' : '24px'}
                              borderRadius="full"
                              color={isCar ? 'white' : 'button'}
                            />
                          </Center>

                          <Box>
                            <Text
                              noOfLines={2}
                              fontSize="12px"
                              textColor="text.100"
                            >
                              {item.content}
                            </Text>

                            <Text as="span" fontSize="12px" textColor="blue.0">
                              Saiba mais
                            </Text>
                            <Text fontSize="10px" textColor="text.500">
                              1 min
                            </Text>
                          </Box>
                        </Flex>
                      </Card>
                    );
                  })}
                </VStack>
              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            {isError || isLoading ? (
              <></>
            ) : (
              <Flex w="100%" justifyContent="center">
                <Button
                  isLoading={loadingClear}
                  onClick={() => clearAll()}
                  isDisabled={notification.length === 0}
                  borderColor="mutable.100"
                  variant="outline"
                >
                  Limpar notificações
                </Button>
              </Flex>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
