import React from 'react';
import { Text, Flex, Button } from '@chakra-ui/react';
import Onboarding from 'components/Onboarding';
import { scheduleTexts } from 'utils/texts';

interface ForwardHeaderProps {
  navigateTo?: () => void;
  title: string;
  actionText?: string;
  step?: number | null;
}

export const ForwardHeader: React.FC<ForwardHeaderProps> = ({
  navigateTo,
  title,
  actionText,
  step,
}) => {
  return (
    <Flex
      zIndex={0}
      bg="white"
      py="24px"
      px="18px"
      rounded="base"
      justify="space-between"
      align={{ base: 'start', md: 'center' }}
      mb="20px"
      flexDir={{ base: 'column', md: 'row' }}
    >
      <Text fontSize="18px" mr="8px" color="GrayText">
        {title}
      </Text>
      {actionText && navigateTo && (
        <Onboarding
          type="schedules"
          isOpen={step === 2}
          step={2}
          contents={scheduleTexts.step2}
          placement="left-start"
        >
          <Button
            mt={{ base: '8px', md: '0px' }}
            minW="140px"
            onClick={navigateTo}
          >
            {actionText}
          </Button>
        </Onboarding>
      )}
    </Flex>
  );
};
