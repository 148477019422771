import React, { useMemo } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';
import {
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Icon,
  Flex,
} from '@chakra-ui/react';
import {
  removeWidget,
  setCardSize,
  useAppDispatch,
  useAppSelector,
} from '@spartalabs/pdc-core';
import Onboarding from 'components/Onboarding';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { GA_CUSTOMIZE_WIDGETS_LABEL } from 'utils/constants';
import { getWidgetById } from 'utils/functions';
import { dashboardTexts } from 'utils/texts';

export const CardOptionsMenu: React.FC<{ values: CardOptions }> = ({
  values: { rowIndex, widget, cardIndex },
}) => {
  const userStep = useAppSelector(state => state.authSlicer.user.step);
  const dispatch = useAppDispatch();

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_CUSTOMIZE_WIDGETS_LABEL,
  });

  const isOpen = useMemo(() => {
    return rowIndex === 0 && cardIndex === 0 && userStep === 4;
  }, [userStep, cardIndex, rowIndex]);

  return (
    <Menu>
      <Onboarding
        contents={dashboardTexts.step4}
        type="dashboard"
        isOpen={isOpen}
        step={4}
        placement="right-end"
        bg="white"
        borderRadius="4px"
        as={MenuButton}
        aria-label="Abrir edição de card"
        p={2}
        pb={5}
        transition="all 0.3s"
        _focus={{ boxShadow: 'none' }}
        _hover={{ borderColor: 'grey.500' }}
        h="10px"
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderColor="grey.200"
      >
        <Text color="button" fontSize="24px">
          ...
        </Text>
      </Onboarding>
      <MenuList
        zIndex="dropdown"
        bg="button"
        sx={{
          '&::-webkit-scrollbar': {
            borderRadius: '8px',
            backgroundColor: 'ocean.100',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'ocean.200',
          },
        }}
        maxHeight="90vh"
        overflowY="auto"
      >
        <MenuItem
          _focus={{ textDecoration: 'underline' }}
          display={{ base: 'none', lg: 'flex' }}
          onClick={() =>
            dispatch(setCardSize({ rowIndex, id: widget.id, size: '50%' }))
          }
          bg="button"
          justifyContent="space-around"
          fontSize="14px"
          isDisabled={!widget.canBeHalf}
        >
          <Flex w="15%" justify="center" mr="8px">
            {widget.size === '50%' && (
              <Icon
                as={AiOutlineCheck}
                color="white"
                h="16px"
                w="16px"
                alignSelf="center"
              />
            )}
          </Flex>
          <Text w="100%">Metade da largura (50%)</Text>
        </MenuItem>
        <MenuItem
          _focus={{ textDecoration: 'underline' }}
          display={{ base: 'none', lg: 'flex' }}
          onClick={() =>
            dispatch(setCardSize({ rowIndex, id: widget.id, size: '100%' }))
          }
          bg="button"
          justifyContent="space-around"
          fontSize="14px"
        >
          <Flex w="15%" justify="center" mr="8px">
            {widget.size === '100%' && (
              <Icon
                as={AiOutlineCheck}
                color="white"
                h="16px"
                w="16px"
                alignSelf="center"
              />
            )}
          </Flex>
          <Text w="100%">Largura total (100%)</Text>
        </MenuItem>

        <MenuDivider
          aria-hidden="true"
          display={{ base: 'none', lg: 'flex' }}
          mx="12px"
        />

        <MenuItem
          _focus={{ textDecoration: 'underline' }}
          onClick={() => {
            analyticsEvent(`Widget Removido: ${getWidgetById(widget.id)}`);
            dispatch(removeWidget({ rowIndex, id: widget.id }));
          }}
          bg="button"
          justifyContent="space-around"
          fontSize="14px"
        >
          <Flex w="15%" justify="center" mr="8px">
            <Icon
              as={FiTrash}
              color="white"
              h="16px"
              w="16px"
              alignSelf="center"
            />
          </Flex>
          <Text w="100%">Remover o quadro</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
