import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { BsImage } from 'react-icons/bs';
import { HiOutlineFilter } from 'react-icons/hi';
import { IoMdColorWand } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerContent,
  useBreakpointValue,
  useDisclosure,
  Text,
  IconButton,
  Tooltip,
  useBreakpoint,
  Image,
  Flex,
  Center,
  Button,
  Icon,
  useMediaQuery,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import {
  clearForm,
  setGlobalDate,
  updatesGlobalDate,
  useAppDispatch,
  useAppSelector,
} from '@spartalabs/pdc-core';
import pen from 'assets/svg/pen.svg';
import Onboarding from 'components/Onboarding';
import { addMonths } from 'date-fns';
import { format } from 'date-fns';
import { urlFormatter } from 'utils/formaters';
import { checkDateRange } from 'utils/functions';
import { dashboardTexts, modelsTexts } from 'utils/texts';
import nav from '../../assets/svg/navIcon.svg';
import { CustomMenu } from './components/Custom/CustomMenu';
import { CustomModal } from './components/Custom/CustomModal';
import { FilterMenu } from './components/FilterMenu';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { SidebarContent } from './components/SideContent';

interface SideBarProps {
  children: ReactNode;
}

const defaultDate = [
  {
    startDate: addMonths(new Date(), -12),
    endDate: new Date(),
    key: 'default',
  },
];

function isDefaultDate(date: DateProps[]) {
  if (date[0].key === 'default') return true;
  return false;
}

export default function SideBar({ children }: SideBarProps) {
  const dispatch = useAppDispatch();
  const { globalDate } = useAppSelector(state => state.globalDataSlicer);

  const { logo, title, token, step, onbModels } = useAppSelector(
    state => state.authSlicer.user,
  );

  const rightMenu = useDisclosure();
  const leftMenu = useDisclosure();
  const modalMenu = useDisclosure();
  const filterMenu = useDisclosure();

  const isOnboarding = useMemo(() => step === 5, [step]);

  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  const [isCollapsed, setIsCollapsed] = useState(false);
  const breakpoint = useBreakpoint();

  const { pathname } = useLocation();

  if (!pathname.includes('novo-agendamento')) {
    dispatch(clearForm());
  }

  const variant = useBreakpointValue({
    base: rightMenu.isOpen,
    xl: false,
  });

  useEffect(() => {
    if (globalDate[0].key === 'default') {
      dispatch(setGlobalDate(defaultDate));
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const { isDashboard, shouldHide, breads } = useMemo(() => {
    const formattedUrl = urlFormatter(pathname);

    const hide = {
      isDashboard: false,
      shouldHide: true,
    };

    switch (formattedUrl) {
      case 'Início':
        return {
          isDashboard: true,
          shouldHide: false,
          breads: null,
        };

      case 'Novo Agendamento':
        return { ...hide, breads: null };
      case 'Mediação':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Faturamento', path: '/faturamento' },
            { name: 'Mediação' },
          ],
        };
      case 'Criação de usuário':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Criar usuário' },
          ],
        };
      case 'Canais de atendimento':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Central de dúvidas' },
          ],
        };
      case 'Pergunta':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Central de dúvidas', path: '/centraldeduvidas' },
            { name: 'Duvida' },
          ],
        };
      case 'Criar pergunta':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Central de dúvidas', path: '/centraldeduvidas' },
            { name: 'Criar dúvida' },
          ],
        };
      case 'Editar pergunta':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Central de dúvidas', path: '/centraldeduvidas' },
            { name: 'Editar dúvida' },
          ],
        };
      case 'Editar categorias':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Central de dúvidas', path: '/centraldeduvidas' },
            { name: 'Editar categorias' },
          ],
        };

      case 'Lista de perguntas':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Central de dúvidas', path: '/centraldeduvidas' },
            { name: 'Duvidas' },
          ],
        };
      case 'Logs':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Log de ações' },
          ],
        };

      case 'Contrato':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Gestão de contratos', path: '/gestaodecontratos' },
            { name: 'Contrato' },
          ],
        };
      case 'Veículos':
        return {
          ...hide,
          breads: [{ name: 'Início', path: '/inicio' }, { name: 'Veículos' }],
        };

      case 'Multas':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Gestão de Veículos' },
            { name: 'Multas' },
          ],
        };

      case 'Indicar condutor':
        return {
          ...hide,
          breads: [
            { name: 'Início', path: '/inicio' },
            { name: 'Gestão de Veículos' },
            { name: 'Multas', path: '/gestao/multas' },
            { name: 'Indicar condutor' },
          ],
        };

      default:
        return {
          isDashboard: false,
          shouldHide: false,
        };
    }
  }, [pathname]);

  useEffect(() => {
    if (!variant && rightMenu.isOpen) rightMenu.onClose();
  }, [variant]);

  useEffect(() => {
    if (breakpoint !== 'xl') setIsCollapsed(false);
  }, [breakpoint]);

  if (!token) return <Box> {children} </Box>;
  if (pathname === '/login') return <Box> {children} </Box>;

  return (
    <Box minH="100vh" bg="background">
      <CustomMenu
        isOpen={leftMenu.isOpen || step === 3}
        onClose={leftMenu.onClose}
      />

      <FilterMenu
        path={pathname}
        isOpen={filterMenu.isOpen}
        onClose={filterMenu.onClose}
      />

      <CustomModal
        userImage={logo}
        userTitle={title}
        isOpen={modalMenu.isOpen}
        onClose={modalMenu.onClose}
      />
      <SidebarContent
        sideBarName="1"
        onClose={() => rightMenu.onClose}
        isCollapsed={isCollapsed}
        display={{ base: 'none', xl: 'block' }}
      />

      <Drawer
        autoFocus={false}
        isOpen={variant || false}
        placement="left"
        onClose={rightMenu.onClose}
        returnFocusOnClose={false}
        onOverlayClick={rightMenu.onClose}
        size={{ base: 'xs', sm: 'sm' }}
      >
        <DrawerContent>
          <SidebarContent
            sideBarName="2"
            isCollapsed={isCollapsed}
            onClose={rightMenu.onClose}
          />
        </DrawerContent>
      </Drawer>

      <Header onOpen={rightMenu.onOpen} />

      <Box
        pt="60px"
        pl={{ base: 0, xl: isCollapsed ? '86px' : '248px' }}
        transition="ease-in-out 0.3s"
      >
        <Box
          zIndex={11}
          h={6}
          w={6}
          position="fixed"
          mt="16px"
          ml="-15px"
          display={{ base: 'none', xl: 'block' }}
        >
          <Tooltip
            bg="ocean.100"
            label={
              isCollapsed ? 'Expandir menu lateral' : 'Recuar menu lateral'
            }
          >
            <SkipNavContent>
              <IconButton
                aria-label={
                  isCollapsed ? 'Expandir menu lateral' : 'Recuar menu lateral'
                }
                icon={
                  isCollapsed ? (
                    <Image
                      transform="auto"
                      rotate="180deg"
                      alt="remover logo"
                      src={nav}
                    />
                  ) : (
                    <Image alt="remover logo" src={nav} />
                  )
                }
                size="50px"
                minW={0}
                fontSize="28px"
                onClick={() => setIsCollapsed(prev => !prev)}
                borderRadius="999px"
              />
            </SkipNavContent>
          </Tooltip>
        </Box>

        <Box
          bg="mutable.100"
          borderColor="transparent"
          zIndex={1}
          h={3}
          w={3}
          position="fixed"
          display={{ base: 'none', xl: 'block' }}
        >
          <Box
            bg="background"
            zIndex={2}
            h={3}
            w={3}
            borderStartStartRadius="8px"
            borderColor="transparent"
            position="absolute"
          />
        </Box>

        {!shouldHide && (
          <Flex
            alignItems="center"
            mx={{ base: '16px', lg: '32px' }}
            mt="20px"
            mb="24px"
            justifyContent="space-between"
          >
            <HStack spacing="16px">
              <Center
                borderRadius="8.5px"
                bg={logo ? 'transparent' : 'white'}
                boxSize={{ base: '60px', md: '95px' }}
              >
                {logo ? (
                  <Image
                    alt=""
                    borderRadius="8.5px"
                    aspectRatio={1}
                    h="95px"
                    boxSize={{ base: '60px', md: '95px' }}
                    src={typeof logo === 'string' ? logo : logo}
                  />
                ) : (
                  <Icon
                    aria-hidden="true"
                    w="25px"
                    h="25px"
                    color="black"
                    as={BsImage}
                  />
                )}
              </Center>
              <Box mr="20px">
                <Flex flex={1} alignItems="center">
                  <Text
                    maxW={{ base: '100px', md: '240px' }}
                    noOfLines={1}
                    fontSize="sm"
                    color="text.100"
                  >
                    {title || 'Adicione o nome da companhia'}
                  </Text>
                  {isDashboard && (
                    <Onboarding
                      type="dashboard"
                      placement="right-end"
                      step={5}
                      isOpen={isOnboarding}
                      contents={dashboardTexts.step5}
                    >
                      <Center
                        aria-label="abrir editar nome e logo"
                        data-cy="custom-modal-open"
                        onClick={() => modalMenu.onOpen()}
                        as="button"
                        ml="8px"
                        bg="black"
                        minW="24px"
                        minH="24px"
                        borderRadius="full"
                      >
                        <Image alt="" sizes="12px" src={pen} />
                      </Center>
                    </Onboarding>
                  )}
                </Flex>

                <Text fontSize="xs" color="text.200">
                  {urlFormatter(pathname)}
                </Text>
              </Box>
            </HStack>

            <Flex flexDirection={{ base: 'column', md: 'row' }}>
              {!isDefaultDate(globalDate) && (
                <Tag
                  mr="16px"
                  display={{ base: 'none', md: 'flex' }}
                  size="md"
                  variant="solid"
                  bg="white"
                  border="1px solid"
                  borderColor="grey.600"
                >
                  <TagLabel fontSize="14px" color="text.100">
                    {`${format(
                      checkDateRange(globalDate)[0]?.startDate || new Date(),
                      'dd/MM/yyyy',
                    )} ~ ${format(
                      checkDateRange(globalDate)[0]?.endDate || new Date(),
                      'dd/MM/yyyy',
                    )}`}
                  </TagLabel>
                  <TagCloseButton
                    data-cy="remove-filter"
                    onClick={() => {
                      dispatch(setGlobalDate(defaultDate));
                      dispatch(updatesGlobalDate(true));
                    }}
                    color="text.100"
                  />
                </Tag>
              )}
              <HStack spacing="16px" justifyContent="center">
                <Onboarding
                  isOpen={onbModels === 2}
                  step={2}
                  contents={modelsTexts.step2}
                  type="models"
                  placement="left-start"
                  bg={onbModels === 2 ? 'white' : 'transparent'}
                  borderRadius="8px"
                  zIndex={onbModels === 2 ? 9999 : 0}
                >
                  {isLargerThan600 ? (
                    <Button
                      data-cy="filter"
                      variant="outline"
                      leftIcon={
                        <Icon
                          color="mutable.100"
                          as={HiOutlineFilter}
                          h="20px"
                          w="20px"
                        />
                      }
                      onClick={() => filterMenu.onOpen()}
                      fontWeight={400}
                    >
                      Filtrar
                    </Button>
                  ) : (
                    <IconButton
                      flex={1}
                      aria-label="Filtrar"
                      variant="outline"
                      onClick={() => filterMenu.onOpen()}
                      icon={
                        <Icon
                          color="mutable.100"
                          as={HiOutlineFilter}
                          h="20px"
                          w="20px"
                        />
                      }
                    />
                  )}
                </Onboarding>
                {isDashboard && isLargerThan600 && (
                  <Button
                    data-cy="custom-menu-open"
                    leftIcon={<Icon color="white" as={IoMdColorWand} />}
                    bg="mutable.100"
                    onClick={() => leftMenu.onOpen()}
                    fontWeight={400}
                  >
                    Personalizar
                  </Button>
                )}

                {isDashboard && !isLargerThan600 && (
                  <IconButton
                    flex={1}
                    aria-label="Personalizar"
                    bg="mutable.100"
                    onClick={() => leftMenu.onOpen()}
                    icon={
                      <Icon
                        color="white"
                        as={IoMdColorWand}
                        h="20px"
                        w="20px"
                      />
                    }
                  />
                )}
              </HStack>
              {!isDefaultDate(globalDate) && (
                <Tag
                  mt="12px"
                  display={{ base: 'flex', md: 'none' }}
                  size="md"
                  variant="solid"
                  bg="white"
                  border="1px solid"
                  borderColor="grey.600"
                >
                  <TagLabel fontSize="14px" color="text.100" flex={1}>
                    {`${format(
                      checkDateRange(globalDate)[0]?.startDate || new Date(),
                      'dd/MM/yyyy',
                    )} ~ ${format(
                      checkDateRange(globalDate)[0]?.endDate || new Date(),
                      'dd/MM/yyyy',
                    )}`}
                  </TagLabel>
                  <TagCloseButton
                    onClick={() => {
                      dispatch(setGlobalDate(defaultDate));
                      dispatch(updatesGlobalDate(true));
                    }}
                    color="text.100"
                  />
                </Tag>
              )}
            </Flex>
          </Flex>
        )}
        {shouldHide && breads && (
          <Breadcrumb
            spacing="0px"
            separator={<ChevronRightIcon color="gray.500" />}
            textColor="text.100"
            fontSize="14px"
            mb="-20px"
            mt="32px"
            ml="32px"
          >
            {breads?.map(bread => (
              <BreadcrumbItem key={bread.name}>
                <BreadcrumbLink isCurrentPage={!bread.path} href={bread.path}>
                  {bread.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}

        <Box minH="85dvh" mb="42px">
          {children}
        </Box>

        {pathname !== 'login' && <Footer />}
      </Box>
    </Box>
  );
}
