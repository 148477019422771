import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseToastOptions, useToast } from '@chakra-ui/react';
import { useViewNotificationMutation } from '@spartalabs/pdc-core';
import { Notification } from 'components';

interface ToastProps {
  step: number;
  action: string;
  errorText?: string;
  helpText?: string;
  successText?: string;
  isLast?: boolean;
}

export function useCustomToast() {
  const toast = useToast();
  const navigate = useNavigate();

  const [viewNot] = useViewNotificationMutation();

  const handleView = useCallback(async (id: string) => {
    try {
      await viewNot({ id, type: 1 }).unwrap();
    } catch {
      //
    }
  }, []);

  const handleToast = ({
    step,
    action,
    errorText,
    helpText,
    successText,
    isLast,
  }: ToastProps) => {
    const options = (): UseToastOptions => {
      switch (step) {
        case 0:
          return {
            id: action,
            title: 'O serviço solicitado se encontra fora do ar',
            description:
              'O serviço [0] se encontra indisponível, se persistir contate o suporte',
            status: 'warning',
          };

        case 1:
          return {
            id: action,
            title: 'O serviço solicitado se encontra fora do ar',
            description:
              'O serviço [1] se encontra indisponível, se persistir contate o suporte',
            status: 'warning',
          };

        case 2:
          return {
            id: action,
            title: `Ocorreu um erro ao ${action}`,
            description:
              errorText ||
              'Ocorreu um erro inesperado, se persistir contate o suporte [0]',
            status: 'error',
          };

        case 3:
          return {
            id: action,
            title: `Ocorreu um erro ao ${action}`,
            description:
              errorText ||
              'Ocorreu um erro inesperado, se persistir contate o suporte [1]',
            status: 'error',
          };

        case 4:
          return {
            id: action,
            title: `Ocorreu um erro ao ${action}`,
            description:
              helpText ||
              'Ocorreu um erro inesperado, se persistir contate o suporte',
            status: 'error',
          };

        case 5:
          return {
            id: action,
            title: action,
            description: successText || '',
            status: 'success',
          };
        case 6:
          return {
            id: action,
            title: action,
            description: successText || errorText || '',
            status: 'error',
          };

        case 7:
          return {
            id: action,
            duration: null,
            position: 'top-right',
            isClosable: false,
            containerStyle: {
              marginTop: isLast ? '80px' : '20px',

              maxWidth: '100%',
            },
            render: () => (
              <Notification
                icon="check"
                type="central"
                title=" Documento CRLV adicionado"
                content="Um documento CRLV foi adicionado para a placa {action}"
                onNavigate={() => {
                  handleView(action);
                  toast.close(action);
                  navigate(`/gestao/veiculos/${action}`);
                }}
                onClose={() => {
                  handleView(action);
                  toast.close(action);
                }}
              />
            ),
          };

        default:
          return {
            id: 'Erro',
            title: 'Ocorreu um erro inesperado',
            description:
              'Ocorreu um erro inesperado, se persistir contate o suporte',
            status: 'error',
          };
      }
    };

    if (!toast.isActive(action)) {
      toast({
        position: 'top',
        duration: 4000,
        isClosable: true,
        ...options(),
      });
    }
    if (step === 7 && toast.isActive(action))
      toast.update(action, { ...options() });
  };

  return { handleToast };
}
