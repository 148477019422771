import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { setPage, useAppDispatch, useAppSelector } from '@spartalabs/pdc-core';
import { GA4_KEY } from 'utils/constants';
import { urlFormatter } from 'utils/formaters';
import { isAllowed, isProd, shouldRecord } from 'utils/functions';

const usePageTracking = () => {
  const dispatch = useAppDispatch();

  const page = useAppSelector(state => state.pagesSlicer.lastPage);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!page && pathname === '/login') {
      dispatch(setPage({ page: '/inicio' }));
    } else {
      if (shouldRecord(pathname) && page) dispatch(setPage({ page: pathname }));
    }

    if (isAllowed() && !ReactGA.isInitialized) ReactGA.initialize(GA4_KEY);

    if (isProd() && isAllowed())
      ReactGA.send({
        hitType: 'pageview',
        page: pathname,
        title: urlFormatter(pathname),
      });
  }, [pathname]);
};

export default usePageTracking;
