import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineSearch, AiOutlineUnorderedList } from 'react-icons/ai';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { useAppSelector } from '@spartalabs/pdc-core';
import { SimpleInput, Onboarding } from 'components';
import { handleCustomUrl } from 'utils/formaters';
import { faqTexts } from 'utils/texts';

export const AutoComplete: React.FC<{
  data: Categorie[];
  isWidget?: boolean;
  setQuestionId?: (item: string | null) => void;
  setCategoryId?: (item: string | null) => void;
}> = ({ data, isWidget, setCategoryId, setQuestionId }) => {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [search, setSearch] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const faqOnb = useAppSelector(state => state.authSlicer.user.onbFAQ);

  const isOnboarding = useMemo(() => faqOnb === 2, [faqOnb]);

  const autoCompleteOptions = useCallback(() => {
    if (!isOpen) return null;

    const lowerCaseSearch = search.toLocaleLowerCase();

    const questions = data
      .flatMap(item => item.FAQs)
      .filter(faq => faq.title.toLocaleLowerCase().includes(lowerCaseSearch));

    const categories = data.filter(faq =>
      faq.title.toLocaleLowerCase().includes(lowerCaseSearch),
    );

    if (!questions.length) return <></>;

    return (
      <Box
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        zIndex={1}
        borderBottomLeftRadius={8}
        borderBottomRightRadius={8}
        w="full"
        mt="-2px"
        position="absolute"
        bg="white"
        maxH={isWidget ? '300px' : '50vh'}
        overflowY="scroll"
      >
        {questions.slice(0, 9).map(question => (
          <Flex
            _hover={{ bg: 'grey.100', borderRadius: '4px' }}
            as="button"
            onClick={() => {
              onClose();
              isWidget
                ? (setQuestionId?.(question.id), setCategoryId?.(null))
                : navigate(
                    `/centraldeduvidas/pergunta/${question.title.trim()}/${
                      question.id
                    }`,
                  );
            }}
            py="12px"
            pr="20px"
            key={question.id}
            align="center"
            w="100%"
            border="0.5px solid"
            borderColor="grey.100"
          >
            <Icon
              ml="8px"
              color="black"
              as={AiOutlineSearch}
              h="16px"
              w="16px"
              alignSelf="center"
            />
            <Text noOfLines={2} ml="8px" color="text.100">
              {question.title}
            </Text>

            <Icon
              ml="auto"
              color="black"
              aria-label="Mostrar senha"
              as={HiOutlineChevronRight}
              h="24px"
              w="24px"
              alignSelf="center"
            />
          </Flex>
        ))}

        {categories.length > 0 && questions.length && (
          <Text px="20px" py="8px" pl="8px" bg="grey.300" textColor="button">
            Categorias
          </Text>
        )}

        {categories.slice(0, 9).map(
          category =>
            category.FAQs.length > 0 && (
              <Flex
                onClick={() => {
                  onClose();
                  isWidget
                    ? (setCategoryId?.(category.id), setQuestionId?.(null))
                    : navigate(
                        `/centraldeduvidas/lista/${handleCustomUrl(
                          category.title,
                        )}/${category.id}`,
                      );
                }}
                _hover={{ bg: 'grey.100' }}
                as="button"
                py="12px"
                pr="20px"
                key={category.id}
                borderRadius="4px"
                align="center"
                w="100%"
                border="0.5px solid"
                borderColor="grey.100"
              >
                <Icon
                  ml="8px"
                  color="black"
                  as={AiOutlineUnorderedList}
                  h="16px"
                  w="16px"
                  alignSelf="center"
                />
                <Text ml="8px" color="text.100">
                  {category.title}
                </Text>

                <Icon
                  ml="auto"
                  color="black"
                  aria-label="Mostrar senha"
                  as={HiOutlineChevronRight}
                  h="24px"
                  w="24px"
                  alignSelf="center"
                />
              </Flex>
            ),
        )}
      </Box>
    );
  }, [search, isOpen]);

  const handleHeaderClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onClose();
        setSearch('');
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [containerRef]);

  return (
    <Flex
      onClick={handleHeaderClick}
      height="auto"
      position="relative"
      mx="auto"
      mt={isWidget ? '0px' : '40px'}
      w={isWidget ? '100%' : { md: '70%', base: '95%' }}
      zIndex={{ md: isOnboarding ? 9999 : 8, base: 8 }}
    >
      <Onboarding
        w="100%"
        isOpen={isOnboarding}
        step={2}
        placement="bottom"
        type="Faq"
        contents={faqTexts.step2}
      >
        <Box ref={containerRef}>
          <SimpleInput
            value={search}
            onFocus={() => onOpen()}
            ref={inputRef}
            textColor="black"
            _placeholder={{ textColor: 'black' }}
            placeholder="Digite a sua busca"
            onChange={e => setSearch(e.target.value)}
            borderBottomRadius={isOpen ? 0 : '4px'}
          />

          {isOpen && data && autoCompleteOptions()}
        </Box>
      </Onboarding>
    </Flex>
  );
};
