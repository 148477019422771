/* eslint-disable @typescript-eslint/no-explicit-any */
import { SetStateAction } from 'react';
import { downloadSheet } from '@spartalabs/pdc-core';
import { AxiosResponse, AxiosError } from 'axios';
import { PluginOptionsByType } from 'chart.js';
import {
  addDays,
  addMonths,
  differenceInDays,
  differenceInMonths,
} from 'date-fns';
import { colors } from 'theme/components/colors';
import { v4 as uuidv4 } from 'uuid';
import { months, monthsToNum } from './constants';

export const defaultChartPlugin = (datasetsLenght: number) =>
  ({
    legend: {
      align: 'start',
      display: datasetsLenght > 1 ? true : false,
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: false,
    },
  } as PluginOptionsByType<'line' | 'bar' | 'doughnut'>);

export const chartDaysLabels = (dates: string[]) => {
  const labels = new Set(
    dates.map(date => {
      const formatDate = date.split('-').reverse();
      formatDate[1] = months[parseInt(formatDate[1], 10) - 1];
      return formatDate.join('/');
    }),
  );

  return Array.from(labels);
};

export const chartMonthsLabels = (dates: string[]) => {
  const labels = new Set(
    dates.map(date => {
      const formatDate = date.split('-').reverse();
      formatDate[0] = months[parseInt(formatDate[0], 10) - 1];
      return formatDate.join('/');
    }),
  );

  return Array.from(labels);
};

export const chartDaysLabelsMocked = (date1: Date, date2: Date) => {
  const labels = [];

  for (
    let index = 0;
    index <= Math.abs(differenceInDays(date1, date2));
    index++
  ) {
    const newDay = addDays(date1, index);
    labels.push(
      `${newDay.getDate() < 10 ? '0' + newDay.getDate() : newDay.getDate()}/${
        months[newDay.getMonth()]
      }/${newDay.getFullYear()}`,
    );
  }

  return labels;
};

export const chartMonthsLabelsMocked = (date1: Date, date2: Date) => {
  const labels = [];

  for (
    let index = 0;
    index <= Math.abs(differenceInMonths(date1, date2));
    index++
  ) {
    const newMonth = addMonths(date1, index);
    labels.push(`${months[newMonth.getMonth()]}/${newMonth.getFullYear()}`);
  }

  return labels;
};

export const handleMonths = (year: number) => [
  {
    abr: 'Jan',
    start: `January 01, ${year}, 00:00:00`,
    end: `January 31, ${year}, 23:59:59`,
  },
  {
    abr: 'Fev',
    start: `February 01, ${year}, 00:00:00 `,
    end: `February 28, ${year}, 23:59:59 `,
  },
  {
    abr: 'Mar',
    start: `March 01, ${year}, 00:00:00`,
    end: `March 31, ${year}, 23:59:59`,
  },
  {
    abr: 'Abr',
    start: `April 01, ${year}, 00:00:00`,
    end: `April 30, ${year}, 23:59:59`,
  },
  {
    abr: 'Mai',
    start: `May 01, ${year}, 00:00:00`,
    end: `May 31, ${year}, 23:59:59`,
  },
  {
    abr: 'Jun',
    start: `June 01, ${year}, 00:00:00`,
    end: `June 30, ${year}, 23:59:59`,
  },
  {
    abr: 'Jul',
    start: `July 01, ${year}, 00:00:00`,
    end: `July 31, ${year}, 23:59:59`,
  },
  {
    abr: 'Ago',
    start: `August 01, ${year}, 00:00:00`,
    end: `August 31, ${year}, 23:59:59`,
  },
  {
    abr: 'Set',
    start: `September 01, ${year}, 00:00:00`,
    end: `September 30, ${year}, 23:59:59`,
  },
  {
    abr: 'Out',
    start: `October 01, ${year}, 00:00:00`,
    end: `October 31, ${year}, 23:59:59`,
  },
  {
    abr: 'Nov',
    start: `November 01, ${year}, 00:00:00  `,
    end: `November 30, ${year}, 23:59:59`,
  },
  {
    abr: 'Dez',
    start: `December 01, ${year}, 00:00:00 `,
    end: `December 31, ${year}, 23:59:59`,
  },
];

export const getMapColor = (selected?: string) => {
  const color = '#596580';
  const regionsArray = [
    'Brasil',
    'Sudeste',
    'Sul',
    'Norte',
    'Nordeste',
    'Centro-Oeste',
  ];

  if (selected) {
    if (regionsArray.includes(selected)) {
      if (selected === 'Brasil')
        return {
          regions: {
            north: color,
            northeast: color,
            centralWest: color,
            southeast: color,
            south: color,
          },
        };

      if (selected === 'Sudeste')
        return {
          regions: {
            southeast: color,
          },
        };

      if (selected === 'Sul')
        return {
          regions: {
            south: color,
          },
        };

      if (selected === 'Norte')
        return {
          regions: {
            north: color,
          },
        };

      if (selected === 'Nordeste')
        return {
          regions: {
            northeast: color,
          },
        };

      if (selected === 'Centro-Oeste')
        return {
          regions: {
            centralWest: color,
          },
        };
    }
  }

  return {};
};

export const getMapActive = (selected?: string) => {
  const regionsArray = ['Brazil', 'SE', 'SO', 'NE', 'NO', 'CW'];

  if (selected) {
    if (regionsArray.includes(selected)) {
      if (selected === 'Brazil')
        return {
          se: true,
          so: true,
          ne: true,
          no: true,
          cw: true,
        };

      if (selected === 'SE')
        return {
          se: true,
        };

      if (selected === 'SO')
        return {
          so: true,
        };

      if (selected === 'SE')
        return {
          ne: true,
        };

      if (selected === 'NO')
        return {
          no: true,
        };

      if (selected === 'CW')
        return {
          cw: true,
        };
    }
  }

  return {};
};

export function getBarsColor(region: string, type: string) {
  if (type === 'Idade' || type === 'age') {
    if (region === 'Sudeste' || region === 'SE') return colors.se_region.age;
    if (region === 'Sul' || region === 'SO') return colors.so_region.age;
    if (region === 'Nordeste' || region === 'NE') return colors.ne_region.age;
    if (region === 'Norte' || region === 'NO') return colors.no_region.age;
    if (region === 'Centro-Oeste' || region === 'CW')
      return colors.cw_region.age;
    return colors.brazil.age;
  }

  if (region === 'Sudeste' || region === 'SE') return colors.se_region.km;
  if (region === 'Sul' || region === 'SO') return colors.so_region.km;
  if (region === 'Nordeste' || region === 'NE') return colors.ne_region.km;
  if (region === 'Norte' || region === 'NO') return colors.no_region.km;
  if (region === 'Centro-Oeste' || region === 'CW') return colors.cw_region.km;
  return colors.brazil.km;
}

export function regionToRegionDto(region: string | null) {
  if (!region) return 'Brasil';

  if (region.toLocaleLowerCase() === 'se') return 'Sudeste';

  if (region.toLocaleLowerCase() === 'so') return 'Sul';

  if (region.toLocaleLowerCase() === 'ne') return 'Nordeste';

  if (region.toLocaleLowerCase() === 'no') return 'Norte';

  if (region.toLocaleLowerCase() === 'cw') return 'Centro-Oeste';

  return 'Brasil';
}

export function getStatesByRegion(states: any, region: string) {
  if (region === 'SE')
    return {
      es: states.es,
      sp: states.sp,
      rj: states.rj,
      mg: states.mg,
    };

  if (region === 'SO')
    return {
      pr: states.pr,
      sc: states.sc,
      rs: states.rs,
    };

  if (region === 'NE')
    return {
      ma: states.ma,
      pi: states.pi,
      ce: states.ce,
      rn: states.rn,
      pb: states.pb,
      pe: states.pe,
      al: states.al,
      se: states.se,
      ba: states.ba,
    };

  if (region === 'NO')
    return {
      am: states.am,
      ac: states.ac,
      rr: states.rr,
      ro: states.ro,
      ap: states.ap,
      pa: states.pa,
      to: states.to,
    };

  return {
    mt: states.mt,
    go: states.go,
    ms: states.ms,
    df: states.df,
  };
}

export function getRegionsOrStatesByRegion(values: any, region: string) {
  if (!values) return { values: [{ label: '', value: 0 }] };

  const aux: any = { values: [] };

  if (region === 'SE')
    aux.values = [values.es, values.sp, values.rj, values.mg];

  if (region === 'SO') aux.values = [values.pr, values.sc, values.rs];

  if (region === 'NE')
    aux.values = [
      values.ma,
      values.pi,
      values.ce,
      values.rn,
      values.pb,
      values.pe,
      values.al,
      values.se,
      values.ba,
    ];

  if (region === 'NO')
    aux.values = [
      values.am,
      values.ac,
      values.rr,
      values.ro,
      values.ap,
      values.pa,
      values.to,
    ];

  if (region === 'CW')
    aux.values = [values.mt, values.go, values.ms, values.df];

  if (region === 'Brazil')
    aux.values = [
      values.se,
      values.so,
      values.ne,
      values.no,
      values.cw,
      values.total,
    ];

  if (region === 'ac') aux.values = [values.ac];
  if (region === 'al') aux.values = [values.al];
  if (region === 'ap') aux.values = [values.ap];
  if (region === 'am') aux.values = [values.am];
  if (region === 'ba') aux.values = [values.ba];
  if (region === 'ce') aux.values = [values.ce];
  if (region === 'es') aux.values = [values.es];
  if (region === 'go') aux.values = [values.go];
  if (region === 'ma') aux.values = [values.ma];
  if (region === 'mt') aux.values = [values.mt];
  if (region === 'ms') aux.values = [values.ms];
  if (region === 'mg') aux.values = [values.mg];
  if (region === 'pa') aux.values = [values.pa];
  if (region === 'pb') aux.values = [values.pb];
  if (region === 'pr') aux.values = [values.pr];
  if (region === 'pe') aux.values = [values.pe];
  if (region === 'pi') aux.values = [values.pi];
  if (region === 'rj') aux.values = [values.rj];
  if (region === 'rn') aux.values = [values.rn];
  if (region === 'rs') aux.values = [values.rs];
  if (region === 'ro') aux.values = [values.ro];
  if (region === 'rr') aux.values = [values.rr];
  if (region === 'sc') aux.values = [values.sc];
  if (region === 'sp') aux.values = [values.sp];
  if (region === 'se') aux.values = [values.se];
  if (region === 'to') aux.values = [values.to];
  if (region === 'df') aux.values = [values.df];

  const response = {
    values: aux.values.filter((value: any) => value !== undefined),
  };

  return response;
}

export function getFilteredColor(type: string) {
  if (type === 'others') return colors.services.others;

  if (type === 'prev') return colors.services.preventive;

  if (type === 'corr') return colors.services.corrective;

  if (type === 'sin') return colors.services.sinister;

  return colors.services.total;
}

export function typeFilterToTypeFilterDto(type: string) {
  if (type === 'others') return 'OUTROS';

  if (type === 'prev' || type === 'preventive') return 'PREVENTIVA';

  if (type === 'corr' || type === 'corrective') return 'CORRETIVA';

  if (type === 'sin' || type === 'sinister') return 'SINISTRO';

  return undefined;
}

export function typeFilterLabelTranslate(type: string) {
  if (type === 'others') return 'Outros';

  if (type === 'prev' || type === 'preventive') return 'Preventiva';

  if (type === 'corr' || type === 'corrective') return 'Corretiva';

  if (type === 'sin' || type === 'sinister') return 'Sinistro';

  return type;
}

export function validatesValue(key: string, value: any) {
  if (
    (value !== undefined && key !== 'total' && key !== 'br') ||
    (Number.isNaN(value) && value > 0)
  )
    return true;

  return false;
}

export function getMapColors(countObject: any, state?: string) {
  const colors: any = {};

  if (countObject)
    Object.keys(countObject).map(key => {
      if (!countObject[key]) return;

      if (state) {
        colors[key] = state === key ? true : false;
      } else {
        colors[key] =
          countObject[key] === 0 || countObject[key] === undefined
            ? undefined
            : !!countObject[key];
      }
    });

  return colors;
}

export function getMapCardTitle(key: string) {
  if (key === 'se') return 'Região Sudeste';

  if (key === 'so') return 'Região Sul';

  if (key === 'ne') return 'Região Nordeste';

  if (key === 'no') return 'Região Norte';

  if (key === 'cw') return 'Região Centro-Oeste';

  if (key == 'ac') return 'Estado do Acre';

  if (key == 'al') return 'Estado de Alagoas';

  if (key == 'ap') return 'Estado do Amapá';

  if (key == 'am') return 'Estado do Amazonas';

  if (key == 'ba') return 'Estado da Bahia';

  if (key == 'ce') return 'Estado do Ceará';

  if (key == 'es') return 'Estado do Espírito Santo';

  if (key == 'go') return 'Estado de Goiás';

  if (key == 'ma') return 'Estado do Maranhão';

  if (key == 'mt') return 'Estado do Mato Grosso';

  if (key == 'ms') return 'Estado do Mato Grosso do Sul';

  if (key == 'mg') return 'Estado de Minas Gerais';

  if (key == 'pa') return 'Estado do Pará';

  if (key == 'pb') return 'Estado da Paraíba';

  if (key == 'pr') return 'Estado do Paraná';

  if (key == 'pe') return 'Estado de Pernambuco';

  if (key == 'pi') return 'Estado do Piauí';

  if (key == 'rj') return 'Estado do Rio de Janeiro';

  if (key == 'rn') return 'Estado do Rio Grande do Norte';

  if (key == 'rs') return 'Estado do Rio Grande do Sul';

  if (key == 'ro') return 'Estado de Rondônia';

  if (key == 'rr') return 'Estado de Roraima';

  if (key == 'sc') return 'Estado de Santa Catarina';

  if (key == 'sp') return 'Estado de São Paulo';

  if (key == 'se') return 'Estado de Sergipe';

  if (key == 'to') return 'Estado de Tocantins';

  if (key == 'df') return 'Distrito Federal';

  return 'Regiões do Brasil';
}

function saveDownloadFile(response: AxiosResponse<any, any>, title: string) {
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], {
    type: type,
  });

  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = `${title}`;
  link.click();
}

export async function downloadFile(
  screen: string,
  fileName: string,
  id?: string,
  mediationId?: string,
  costCenter?: string,
) {
  try {
    const response = await downloadSheet(screen, id, mediationId, costCenter);
    if (response) saveDownloadFile(response, fileName);
  } catch (error) {
    const axiosError = error as AxiosError;

    if (axiosError?.response?.status === 404)
      throw new Error('Documento não encontrado em nossa base');

    throw new Error('Não foi possível fazer o download a planilha');
  }
}

export function allTypesData(values: RepairChartItem[], labels: string[]) {
  const prevValues: RepairChartItem[] = [];
  const corrValues: RepairChartItem[] = [];
  const sinValues: RepairChartItem[] = [];
  const otherValues: RepairChartItem[] = [];

  labels.map(label => {
    const formattedLabelSplitted = label.split('/').reverse();
    formattedLabelSplitted[1] = monthsToNum[formattedLabelSplitted[1]];
    const labelId = formattedLabelSplitted.join('-');
    const filteredValues = values.filter(value => value.id === labelId);

    if (filteredValues.length > 0) {
      const filteredValueByPrev = filteredValues.filter(
        filteredValue => filteredValue.type === 'preventive',
      );
      if (filteredValueByPrev.length > 0) {
        prevValues.push({
          id: labelId,
          type: 'preventive',
          quantity: filteredValueByPrev[0].quantity,
        });
      } else {
        prevValues.push({
          id: labelId,
          type: 'preventive',
          quantity: 0,
        });
      }

      const filteredValueByCorr = filteredValues.filter(
        filteredValue => filteredValue.type === 'corrective',
      );
      if (filteredValueByCorr.length > 0) {
        corrValues.push({
          id: labelId,
          type: 'corrective',
          quantity: filteredValueByCorr[0].quantity,
        });
      } else {
        corrValues.push({
          id: labelId,
          type: 'corrective',
          quantity: 0,
        });
      }

      const filteredValueBySin = filteredValues.filter(
        filteredValue => filteredValue.type === 'sinister',
      );

      if (filteredValueBySin.length > 0) {
        sinValues.push({
          id: labelId,
          type: 'sinister',
          quantity: filteredValueBySin[0].quantity,
        });
      } else {
        sinValues.push({
          id: labelId,
          type: 'sinister',
          quantity: 0,
        });
      }

      const filteredValueByOthers = filteredValues.filter(
        filteredValue => filteredValue.type === 'others',
      );
      if (filteredValueByOthers.length > 0) {
        otherValues.push({
          id: labelId,
          type: 'others',
          quantity: filteredValueByOthers[0].quantity,
        });
      } else {
        otherValues.push({
          id: labelId,
          type: 'others',
          quantity: 0,
        });
      }
    } else {
      prevValues.push({
        id: labelId,
        type: 'preventive',
        quantity: 0,
      });
      corrValues.push({
        id: labelId,
        type: 'corrective',
        quantity: 0,
      });
      sinValues.push({
        id: labelId,
        type: 'sinister',
        quantity: 0,
      });
      otherValues.push({
        id: labelId,
        type: 'others',
        quantity: 0,
      });
    }
  });

  return [
    {
      label: 'Preventiva',
      values: prevValues,
      fill: {
        type: 'start',
        color: getFilteredColor('prev'),
      },
      accessor: 'quantity',
    },
    {
      label: 'Corretiva',
      values: corrValues,
      fill: {
        type: 'start',
        color: getFilteredColor('corr'),
      },
      accessor: 'quantity',
    },
    {
      label: 'Sinistro',
      values: sinValues,
      fill: {
        type: 'start',
        color: getFilteredColor('sin'),
      },
      accessor: 'quantity',
    },
    {
      label: 'Outros',
      values: otherValues,
      fill: {
        type: 'start',
        color: getFilteredColor('others'),
      },
      accessor: 'quantity',
    },
  ];
}

export function regionDtoToRegion(region?: SetStateAction<string>) {
  if (!region) return 'br';

  const regionToString = String(region);

  if (regionToString.toLowerCase() === 'sudeste') return 'se';
  if (regionToString.toLowerCase() === 'sul') return 'so';
  if (regionToString.toLowerCase() === 'nordeste') return 'ne';
  if (regionToString.toLowerCase() === 'norte') return 'no';
  if (regionToString.toLowerCase() === 'centro-oeste') return 'cw';

  return 'br';
}

export function generateId() {
  return uuidv4();
}

function checkDate(val?: Date | number) {
  if (!val) return new Date();

  if (Object.prototype.toString.call(val) === '[object Date]') {
    return val;
  }

  return new Date(val);
}

export function checkDateRange(val?: DateProps[]) {
  if (val) {
    return [
      {
        startDate: checkDate(val[0].startDate),
        endDate: checkDate(val[0].endDate),
        key: val[0].key,
      },
    ] as DateProps[];
  }

  return [
    {
      startDate: checkDate(new Date()),
      endDate: checkDate(new Date()),
      key: 'selection',
    },
  ] as DateProps[];
}

export function isHeaderDateSelectShown(path: string) {
  if (path.includes('distdefrotas')) return false;

  if (path.includes('modelos')) return false;

  if (path.includes('novo-agendamento')) return false;

  return true;
}

export function isDev() {
  if (
    !process.env.REACT_APP_ENV ||
    process.env.REACT_APP_ENV === 'development'
  ) {
    return true;
  }

  return false;
}

export function isStaging() {
  if (process.env.REACT_APP_ENV === 'staging') {
    return true;
  }

  return false;
}

export function isProd() {
  if (process.env.REACT_APP_ENV === 'production') {
    return true;
  }

  return false;
}

export function isAllowed() {
  const status = localStorage.getItem('trackingAuthorizationStatus');

  const isIos = /iphone|ipad|ipod/.test(
    window.navigator.userAgent.toLowerCase(),
  );

  if (status !== 'authorized' && isIos) return false;

  return true;
}

export function translateColor(color: string) {
  if (color.toLocaleLowerCase() === 'red') return 'Vermelho';

  if (color.toLocaleLowerCase() === 'black') return 'Preto';

  if (color.toLocaleLowerCase() === 'button') return 'Azul';

  return color;
}

export function getWidgetById(id: number) {
  if (id === 0) return 'Regiões do brasil - distribuição da frota pelo país';

  if (id === 1) return 'Gráfico de serviços';

  if (id === 2) return 'Total de atendimentos realizados';

  if (id === 3) return 'Entrada e saída de manutenções';

  if (id === 4) return 'Distribuição de veículos por idade e km';

  if (id === 5) return 'Distribuição de frotas por modelos';

  return 'Não Definido';
}

export function getMaxFromArrays(arrays: Array<number>[]) {
  const arraysMax = arrays.map(array => Math.max(...array));

  return Math.max(...arraysMax);
}

export function shouldRecord(path: string) {
  switch (path) {
    case '/login':
    case '/novo-agendamento/servicos':
    case '/novo-agendamento/agenda':
    case '/novo-agendamento/lote':
    case '/novo-agendamento/revisao':
    case '/novo-agendamento/imagens':
      return false;
    default:
      return true;
  }
}
